var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사업장", value: _vm.fireFighting.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "관리번호",
                    value: _vm.fireFighting.manageNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "설비유형",
                    value: _vm.fireFighting.sopFireFightingTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "설비종류",
                    value: _vm.fireFighting.sopFireFightingTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "소방설비 및 장비명",
                    value: _vm.fireFighting.fireFightingName,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "점검이력",
                columns: _vm.grid.columns,
                data: _vm.fireFighting.histroy,
                gridHeight: _vm.gridHeight,
              },
              on: { linkClick: _vm.linkClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "소방 이슈점검 이력 목록",
                columns: _vm.gridIssueCheck.columns,
                data: _vm.fireFighting.issueChecks,
                gridHeight: _vm.gridHeight,
              },
              on: { linkClick: _vm.linkClick },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }